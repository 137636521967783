import React, { useState, useEffect } from 'react';
import './App.css';
import SignIn from './Components/SignIn';
import Dashboard from './Components/dashboard';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn');
    const storedUsername = localStorage.getItem('username');
    if (loggedIn === 'true' && storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
    }
  }, []);

  const users = [
  { username: 'admin', password: 'vlh@123', roles: ['all'] },
  { username: 'huukhanh', password: 'abc@123', roles: ['sewing', 'downtime', 'efficiency'] },
  { username: 'hongvan', password: 'abc@123', roles: ['sewing', 'downtime', 'efficiency', 'production'] },
  { username: 'khacdung', password: 'abc@123', roles: ['sewing', 'downtime', 'efficiency'] },
  { username: 'hochau', password: 'abc@123', roles: ['sewing', 'downtime', 'efficiency'] },
  { username: 'tantrieu', password: 'abc@123', roles: ['sewing', 'downtime', 'efficiency', 'audit', 'quality', 'production'] },
  { username: 'yennhi', password: 'abc@123', roles: ['sewing', 'downtime', 'efficiency'] },
  { username: 'thunguyen', password: 'abc@123', roles: ['sewing', 'downtime', 'efficiency', 'quality', 'production'] },
  { username: 'phongqa', password: 'abc@123', roles: ['audit', 'quality'] },
  { username: 'tranglean', password: 'abc@123', roles: ['audit'] },
  { username: 'vandien', password: 'abc@123', roles: ['downtime', 'efficiency', 'quality', 'production'] },
  { username: 'thanhphong', password: 'abc@123', roles: ['quality', 'production'] },
];

  const checkLogin = (inputUsername, password) => {
    const user = users.find(u => u.username === inputUsername && u.password === password);
    if (user) {
      setIsLoggedIn(true);
      setUsername(user.username);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('username', user.username);
      return true;
    }
    return false;
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <Dashboard onLogout={handleLogout} username={username} users={users}/>
      ) : (
        <SignIn checkLogin={checkLogin} />
      )}
    </div>
  );
}

export default App;