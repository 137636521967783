// Dashboard.js
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';
import { Modal, Button } from 'react-bootstrap'; 
import { FaFolder, FaFolderOpen, FaFile } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom';

function Dashboard({ onLogout, username, users }) {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState("welcome");
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState({});
  const hasAccess = (role) => {
    const user = users.find((u) => u.username === username);
    return user?.roles.includes("all") || user?.roles.includes(role);
  };
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleLogoutClick = () => {
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirm = () => {
    setShowLogoutConfirm(false);
    onLogout();
  };

  const navigateTo = (page) => {
    setCurrentPage(page);
  };

  const toggleFolder = (folderName) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));
  };

  return (
    <div className="dashboard d-flex">
      <aside className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <div className="sidebar-header d-flex justify-content-between align-items-center p-3">
          <h1 className="logo m-0">REPORT</h1>

          <button className="nav-link" onClick={handleLogoutClick}>
            Logout
          </button>
        </div>
        <nav className="sidebar-nav">
          <ul className="nav flex-column">
            {/* VLH Reports Folder */}
            {(hasAccess("all") ||
              hasAccess("sewing") ||
              hasAccess("downtime") ||
              hasAccess("efficiency") ||
              hasAccess("audit")) && (
              <li className="nav-item folder-item">
                <button
                  className="nav-link folder-link"
                  onClick={() => toggleFolder("VLH")}
                >
                  {expandedFolders["VLH"] ? <FaFolderOpen /> : <FaFolder />}{" "}
                  Vietlonghung Reports
                </button>

                {expandedFolders["VLH"] && (
                  <ul className="nav flex-column ml-3">
                    {hasAccess("all") && (
                      <>
                        <li className="nav-item">
                          <button
                            className="nav-link"
                            onClick={() => navigateTo("TRAINING")}
                          >
                            Training Online
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            className="nav-link"
                            onClick={() => navigateTo("SKCT")}
                          >
                            Kaizen Suggestion
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            className="nav-link"
                            onClick={() => navigateTo("CUTTING")}
                          >
                            Cutting
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            className="nav-link"
                            onClick={() =>
                              navigateTo("TEMPERATURE AND HUMIDITY")
                            }
                          >
                            Temperature And Humidity
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            className="nav-link"
                            onClick={() => navigateTo("MD FG Report")}
                          >
                            MD FG Report
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            className="nav-link"
                            onClick={() =>
                              navigateTo("SPECIALIZED MACHINE REPORT")
                            }
                          >
                            Specialized Machine Report
                          </button>
                        </li>
                      </>
                    )}
                    {(hasAccess("all") || hasAccess("sewing")) && (
                      <li className="nav-item">
                        <button
                          className="nav-link"
                          onClick={() => navigateTo("SEWING")}
                        >
                          Sewing
                        </button>
                      </li>
                    )}
                    {(hasAccess("all") || hasAccess("downtime")) && (
                      <li className="nav-item">
                        <button
                          className="nav-link"
                          onClick={() => navigateTo("DOWNTIME")}
                        >
                          Downtime
                        </button>
                      </li>
                    )}
                    {(hasAccess("all") || hasAccess("efficiency")) && (
                      <li className="nav-item">
                        <button
                          className="nav-link"
                          onClick={() => navigateTo("Efficiency & CO")}
                        >
                          Efficiency & CO
                        </button>
                      </li>
                    )}
                    {(hasAccess("all") || hasAccess("audit")) && (
                      <li className="nav-item">
                        <button
                          className="nav-link"
                          onClick={() => navigateTo("KSNB")}
                        >
                          Internal Audit
                        </button>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Viettien Reports Folder */}
            {(hasAccess("all") ||
              hasAccess("quality") ||
              hasAccess("production")) && (
              <li className="nav-item folder-item">
                <button
                  className="nav-link folder-link"
                  onClick={() => toggleFolder("viettien")}
                >
                  {expandedFolders["viettien"] ? (
                    <FaFolderOpen />
                  ) : (
                    <FaFolder />
                  )}{" "}
                  Viettien Reports
                </button>
                {expandedFolders["viettien"] && (
                  <ul className="nav flex-column ml-3">
                    {(hasAccess("all") || hasAccess("production")) && (
                      <li className="nav-item">
                        <button
                          className="nav-link"
                          onClick={() => navigateTo("Production Progress")}
                        >
                          Production Progress
                        </button>
                      </li>
                    )}
                    {(hasAccess("all") || hasAccess("quality")) && (
                      <li className="nav-item">
                        <button
                          className="nav-link"
                          onClick={() => navigateTo("Quality Report")}
                        >
                          Quality
                        </button>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </nav>
      </aside>

      <main
        className={`main-content ${
          isSidebarCollapsed ? "sidebar-collapsed" : ""
        }`}
      >
        <header className="main-header d-flex align-items-center p-3">
          <button className="menu-toggle btn btn-dark" onClick={toggleSidebar}>
            ☰
          </button>
          <h2 className="mb-0 ms-3">
            {currentPage === "TRAINING"
              ? "Training Online"
              : currentPage === "SEWING"
              ? "SEWING"
              : currentPage === "DOWNTIME"
              ? "DOWNTIME"
              : currentPage === "SKCT"
              ? "Kaizen Auggestion"
              : currentPage === "KSNB"
              ? "Internal Audit"
              : currentPage === "CUTTING"
              ? "Cutting"
              : currentPage === "Production Progress"
              ? "Production Progress"
              : currentPage === "Quality Report"
              ? "Quality Report"
              : currentPage === "TEMPERATURE AND HUMIDITY"
              ? "Temperature And Humidity"
              : currentPage === "MD FG Report"
              ? "MD FG Report"
              : currentPage === "SPECIALIZED MACHINE REPORT"
              ? "Specialized Machine Report"
              : currentPage === "Efficiency & CO"
              ? "Efficiency & CO"
              : ""}
          </h2>
          <div className="header-right">
            Hello, {username.charAt(0).toUpperCase() + username.slice(1)}
          </div>
        </header>
        <div className="content p-4">
          {currentPage === "TRAINING" && (
            <div className="power-bi-container">
              <iframe
                title="TRAINING ONLINE"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiYmNhNzg3YjItNjRjNy00YjRiLThlMDQtOGQ4NzY3ZGFiMmU3IiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "DOWNTIME" && (
            <div className="power-bi-container">
              <iframe
                title="VLH - DT RECORDING TOOL 20240810"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiNmExOWM3MWItZmZkMC00NDc0LWJmNGQtZGIwMzc5NTMwZGIzIiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "CUTTING" && (
            <div className="power-bi-container">
              <iframe
                title="BÁO CÁO CẮT"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiZWE1YzY4NDYtZDE1Yy00ZWU4LTlkNzctNmEwNTNhYWY2NzY5IiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "SEWING" && (
            <div className="power-bi-container">
              <iframe
                title="SEWING"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiYmQwMTNiOTAtZmQ2Yy00OWEzLWFjMzEtY2EyYjk4MjIxNWJlIiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "SKCT" && (
            <div className="power-bi-container">
              <iframe
                title="VLH_SKCT NEW"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiZTRmMTExNDMtYjhkMS00OWY5LWE0MDItYWE4NjVlYTg5YmM4IiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "KSNB" && (
            <div className="power-bi-container">
              <iframe
                title="KSNBT7"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiYjY4Njc0YmItYjg0NC00MTk0LWE1Y2ItMzEwOTQyMzMyMzA1IiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "Production Progress" && (
            <div className="power-bi-container">
              <iframe
                title="Production Progress"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiM2IzNTRmM2QtYjhkMy00NDBkLWJhODktYzAwMmVlNGNhN2I1IiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "TEMPERATURE AND HUMIDITY" && (
            <div className="power-bi-container">
              <iframe
                title="TEMPERATURE AND HUMIDITY"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiZGU3MmUwYjgtNWFiNC00YTFjLTg1YTMtZTJjN2I2NTUyMTA1IiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "MD FG Report" && (
            <div className="power-bi-container">
              <iframe
                title="MD FG Report"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiMzgzYWU0OTgtMTRjNC00NjI1LWE2MmYtZTlkOGMxOTQ5ZDRiIiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "SPECIALIZED MACHINE REPORT" && (
            <div className="power-bi-container">
              <iframe
                title="SPECIALIZED MACHINE REPORT"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiZWJmNTY2YzEtMTgxMi00ZTc3LWI1OWUtOWRiMTdlYjNhZjYzIiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "Quality Report" && (
            <div className="power-bi-container">
              <iframe
                title="Quality Report"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiZWUwMGE0YjctOTY2OC00ZjFkLWFlMzQtZjFmZDM3YmIxYThiIiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
          {currentPage === "Efficiency & CO" && (
            <div className="power-bi-container">
              <iframe
                title="Efficiency & CO"
                width="100%"
                height="600"
                src="https://app.powerbi.com/view?r=eyJrIjoiN2RmZDI4OTktZDVmNC00YmM2LTg2NTktNjY2ZmM4YjJhOWM0IiwidCI6IjVlMmRlODU4LTBkYTUtNDdiMS1iMzJhLWZiMGJlMDU4MTQ3NyIsImMiOjEwfQ%3D%3D"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          )}
        </div>
      </main>
      <Modal
        show={showLogoutConfirm}
        onHide={() => setShowLogoutConfirm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to log out??</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowLogoutConfirm(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleLogoutConfirm}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Dashboard;
